import { template as template_538afc6f33c34226bc2b5cb9c313f465 } from "@ember/template-compiler";
const FKLabel = template_538afc6f33c34226bc2b5cb9c313f465(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
