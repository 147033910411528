import { template as template_e44f17468e9e48c292a9ee6c987a740b } from "@ember/template-compiler";
const FKControlMenuContainer = template_e44f17468e9e48c292a9ee6c987a740b(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
