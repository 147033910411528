import { template as template_e99fd177674f4a57908d037a4d0b8996 } from "@ember/template-compiler";
const FKText = template_e99fd177674f4a57908d037a4d0b8996(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
