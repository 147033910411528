import { template as template_74db348899324488b39f136c2ea0e9ab } from "@ember/template-compiler";
const WelcomeHeader = template_74db348899324488b39f136c2ea0e9ab(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
