import { template as template_8e0da5a54ade46dd9bee2c8f0e347f6c } from "@ember/template-compiler";
const SidebarSectionMessage = template_8e0da5a54ade46dd9bee2c8f0e347f6c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
