import { template as template_10aa0ce3bbd44baca5d7f5af0d4330ac } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_10aa0ce3bbd44baca5d7f5af0d4330ac(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
